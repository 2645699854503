import {
  Box,
  Chip,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { save as saveConfiguration } from '../helpers/configuration';
import { Act, Beat, BEAT_TYPE, Configuration } from '../types';
import { capitalize, toInt } from '../helpers/transform';

interface ActsAndBeatsProps {
  acts: Act[];
  configuration: Configuration;
  onActAdd: (act: Act) => void;
  onActDelete: (index: number) => void;
  onBeatAdd: (actIndex: number, beat: Beat) => void;
  onBeatDelete: (actIndex: number, beatIndex: number) => void;
}

export const ActsAndBeats = (props: ActsAndBeatsProps) => {
  const {
    control,
    getValues,
    formState,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<Configuration>();

  const [currentActIndex, setCurrentActIndex] = useState<number>(0);
  const [currentBeatIndex, setCurrentBeatIndex] = useState<number>(0);

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Typography variant="h4">Acts</Typography>
        {props.acts.map((_, i) => (
          <Chip
            key={i}
            label={i + 1}
            onClick={() => {
              setCurrentActIndex(i);
              setCurrentBeatIndex(0);
            } }
            sx={{ py: 1, m: 1 }}
            {...currentActIndex === i && { color: 'primary' } }
          />
        ))}
        <Fab
          sx={{ py: 1, m: 1 }}
          size="small"
          onClick={() => {
            props.onActAdd({ beats: [{
              overrideTimerDuration: props.configuration.overrideTimerDuration,
            }] } as Act);
            setCurrentActIndex(props.acts.length);
          }}
        >
          <Add />
        </Fab>
        <Fab
          sx={{ py: 1, m: 1 }}
          size="small"
          onClick={() => {
            if (currentActIndex === props.acts.length - 1) {
              setCurrentActIndex(
                props.acts.length - 2 >= 0
                  ? props.acts.length - 2 
                  : 0
              );
            }
            props.onActDelete(props.acts.length - 1);
          }}
        >
          <Delete />
        </Fab>
      </Box>
      <Box sx={{ mx: 4 }}>
        <Typography variant="h5">Beats</Typography>
        {props?.acts?.[currentActIndex]?.beats?.map((_, i) => (
          <Chip
            key={i}
            label={i + 1}
            onClick={() => setCurrentBeatIndex(i)}
            sx={{ py: 1, m: 1 }}
            {...currentBeatIndex === i && { color: 'primary' } }
          />
        ))}
        <Fab
          sx={{ py: 1, m: 1 }}
          size="small"
          onClick={() => {
            props.onBeatAdd(currentActIndex, {
              overrideTimerDuration: props.configuration.overrideTimerDuration,
            } as Beat);
            setCurrentBeatIndex(props?.acts?.[currentActIndex]?.beats?.length!);
          }}
        >
          <Add />
        </Fab>
        <Fab
          sx={{ py: 1, m: 1 }}
          size="small"
          onClick={() => {
            const beatLength = props?.acts?.[currentActIndex]?.beats?.length;

            if (beatLength) {
              if (currentBeatIndex === beatLength - 1) {
                setCurrentBeatIndex(
                  beatLength - 2 >= 0
                    ? beatLength - 2 
                    : 0
                );
              }

              props.onBeatDelete(currentActIndex, beatLength - 1);
            }
          }}
        >
          <Delete />
        </Fab>
        {props.acts.map((_, i) =>
          props?.acts?.[i]?.beats?.map((_, j) => (
            <Box
              key={`${i}-${j}`}
              sx={{
                ...(currentActIndex !== i || currentBeatIndex !== j) && {
                  display: 'none'
                }
              }}
            >
              <Box sx={{ m: 2 }}>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                {Object.values(BEAT_TYPE).map((type) => (
                  <FormControl key={type}>
                    <RadioGroup row defaultValue={type}>
                      <FormControlLabel
                        value={type}
                        control={
                          <Controller
                            name={`preset.acts.${i}.beats.${j}.type`}
                            control={control}
                            render={({ field: { value } }) => {
                              return (
                                <Radio
                                  size="small"
                                  checked={(value as unknown as BEAT_TYPE) === type}
                                  onChange={() => {
                                    setValue(
                                      `preset.acts.${i}.beats.${j}.type`,
                                      type,
                                    );
                                    saveConfiguration(getValues());
                                  }}
                                />
                              );
                            }}
                          />
                        }
                        label={capitalize(type as unknown as string)}
                      />
                    </RadioGroup>
                  </FormControl>
                ))}
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormControlLabel
                  label="Use Timer Override"
                  control={
                    <Controller
                      name={`preset.acts.${i}.beats.${j}.useTimerOverride`}
                      control={control}
                      render={({ field: { value } }) => {
                        const val = value ?? false;

                        return (
                          <Switch
                            checked={val}
                            onChange={async (_, checked) => {
                              await setValue(
                                `preset.acts.${i}.beats.${j}.useTimerOverride`,
                                checked,
                              );
                              trigger(`preset.acts.${i}.beats.${j}.overrideTimerDuration`);
                              saveConfiguration(getValues());
                            }}
                          />
                        );
                      }}
                    />
                  }
                />
                </Grid>
                </Grid>
              </Box>
              <Box sx={{ m: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Override Timer Duration"
                      placeholder="Enter duration"
                      InputLabelProps={{ shrink: true }}
                      {
                        ...{
                          error: !!formState.errors?.preset?.acts?.[i]?.beats?.[j]?.overrideTimerDuration,
                          helperText: formState.errors?.preset?.acts?.[i]?.beats?.[j]?.overrideTimerDuration?.message,
                        }
                      }
                      {
                        ...register(`preset.acts.${i}.beats.${j}.overrideTimerDuration`, {
                          required: {
                            value: !!watch(`preset.acts.${i}.beats.${j}.useTimerOverride`),
                            message: 'This field is required'
                          },
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(
                              `preset.acts.${i}.beats.${j}.overrideTimerDuration`,
                              toInt(event.target.value),
                            );
                            saveConfiguration(getValues());
                          }
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="One-shot Sound"
                      placeholder="Enter sound name or url"
                      InputLabelProps={{ shrink: true }}
                      {
                        ...register(`preset.acts.${i}.beats.${j}.audioSrc`, {
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(
                              `preset.acts.${i}.beats.${j}.audioSrc`,
                              event.target.value,
                            );
                            saveConfiguration(getValues());
                          }
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Background Sound"
                      placeholder="Enter background sound name or url"
                      InputLabelProps={{ shrink: true }}
                      {
                        ...register(`preset.acts.${i}.beats.${j}.audioBackgroundSrc`, {
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(
                              `preset.acts.${i}.beats.${j}.audioBackgroundSrc`,
                              event.target.value,
                            );
                            saveConfiguration(getValues());
                          }
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))
        )}
      </Box>
    </>
  );
};
