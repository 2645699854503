import { Configuration } from "../types";

const REMY_CONFIGURATION_KEY = 'remyConfiguration_2023_01_12';

export const save = (values: Configuration) => {
  const saved = get() as Configuration;

  if (values.scenario) {
    localStorage.setItem(REMY_CONFIGURATION_KEY, JSON.stringify({
      player: values.player,
      scenario: values.scenario,
      chatSettings: values.chatSettings,
      presets: {
        ...saved.presets,
        ...values.preset?.custom && {
          [values.scenario]: values.preset,
        },
      },
    }));
  }
};

export const get = () => {
  const configurationJSON = localStorage.getItem(REMY_CONFIGURATION_KEY);

  try {
    return configurationJSON ? JSON.parse(configurationJSON) : {};
  } catch (e) {
    console.error('Impossible to parse saved configuration: ', e);
    return {};
  }
}
