import { Toolbar, Typography } from '@mui/material';

import {
  AppBarStyled,
} from './Header.styled';

import { Step } from '../types';

interface HeaderProps {
  step: Step;
  nextStep?: Step
  timer: number;
}

export function Header(props: HeaderProps) {
  const { act, beat, type } = props.step;
  const {
    act: nextAct = -1,
    beat: nextBeat = -1,
    type: nextType,
  } = props.nextStep || {};

  return (
    <AppBarStyled elevation={1} color="inherit" position="fixed">
      <Toolbar>
        <Typography sx={{ flexGrow: 1 }} />
        Cooking
        <Typography sx={{ flexGrow: 1 }} />
        {(act >=0 && beat >=0)
          ? (<>Act: {act + 1} Beat: {beat + 1} ({type}) </>)
          : ''
        }
        {(nextAct > 0 || (nextAct === 0 && nextBeat > 0))
          ? (<>{'=>'} Act: {nextAct + 1} Beat: {nextBeat + 1} ({nextType})</>)
          : ''
        }
        <Typography sx={{ flexGrow: 1 }} />
        {props.timer > 0 && (<>Time: {props.timer}</>)}
        <Typography sx={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBarStyled>
  );
};
