import { Step } from "../types";

export const capitalize = (word: string) => {
  if (!word) return word;

  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export const toInt = (value: string | number) => {
  if (typeof value === 'number') return value;

  const parsed = parseInt(value) ?? undefined;

  return isNaN(parsed) ? undefined : parsed;
}

export const reDoTrigger = RegExp(
  `^dotrigger_(.*)`,
);

export const stepEvent = (step: Step) =>
  `act${step.act + 1}beat${step.beat + 1}${step.part ? '_part2' : ''}`;

export const dateWithMilliseconds = (date: Date) =>
  `${date.toLocaleString()}.${date.getMilliseconds()}`;
