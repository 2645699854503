/* tslint:disable max-line-length */
export const SLATE = {
  50: '#F9FAFC',
  100: '#F1F5F9',
  200: '#E1E7F0',
  300: '#CAD5E0',
  400: '#95A2B7',
  500: '#63738A',
  600: '#475468',
  700: '#334054',
  800: '#1E2939',
  900: '#0F1729',
};

export const BETA_ACCENT_COLORS = {
  1: '#7ED9EB',
  2: '#D3BDFB',
  3: '#2DB1FA',
  4: '#F9CAB0',
};

// These will be getting updated and expanded
export const INWORLD_COLORS = {
  gray: {
    100: '#0F082D',
    90: '#2A2147',
    80: '#534E69',
    70: '#6A667D',
    60: '#807B8F',
    50: '#9693A3',
    40: '#ADAAB7',
    30: '#C4C2CB',
    20: '#DCDADF',
    10: '#F3F2F4',
  },
  warmGray: {
    10: '#FAF8F5',
    20: '#EFEDEC',
    30: '#D8D3D1',
    50: '#96908E',
    70: '#555151',
    80: '#3B3838',
  },
  magenta: {
    50: '#C333DB',
    60: '#A719C2',
    30: '#DE7DF0',
    20: '#ECD8F0',
  },
  purple: {
    10: '#FCFAFF',
    20: '#EEE5FF',
    30: '#BF9FFF',
    40: '#A57CFF',
    50: '#864CFF',
    60: '#561EE5',
    70: '#3000B2',
    // 90: '#312352',
    90: '#391C54',
    100: '#140734',
  },
  green: {
    10: '#F3FCF9',
    20: '#DCF5ED',
    40: '#7AEBC9',
    50: '#1DDBA1',
    60: '#19C28C',
    70: '#13906F',
  },
  orange: {
    50: '#FF8766',
    60: '#E04F28',
    40: '#FFB894',
    20: '#FFEFE7',
  },
};
