import { BEAT_TYPE, ExternalConfiguration, InternalConfiguration } from './app/types';
import externalConfigJson from './defaults.json';

export const externalConfiguration = externalConfigJson as ExternalConfiguration;

export const internalConfiguration: InternalConfiguration = {
  scenario: externalConfiguration.presets?.[0].key, 
  preset: externalConfiguration.presets?.[0].config,
  player: { name: 'Participant' },
  chatSettings: {
    debugMode: false,
    interruptions: false,
  },
};

export const chat = {
  step: {
    act: -1,
    beat: -1,
    type: BEAT_TYPE.STRONG,
  },
};
