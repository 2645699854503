import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { save as saveConfiguration } from '../helpers/configuration';
import { useCallback } from 'react';
import { toInt } from '../helpers/transform';

export const UseBeatTimer = () => {
  const {
    control,
    getValues,
    formState,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<Configuration>();

  const onDefaultDurationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const values = getValues();

    saveConfiguration({
      ...values,
      preset: {
        ...values.preset,
        beatTimer: { defaultDuration: toInt(event.target.value) },
      }
    });
  }, [getValues]);

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Typography variant="h4">Timers</Typography>
        <FormControlLabel
          label="Use beat timer"
          control={
            <Controller
              name={'preset.beatTimer.enabled'}
              control={control}
              render={({ field: { value } }) => {
                const val = value ?? false;

                return (
                  <Switch
                    checked={val}
                    onChange={async (_, checked) => {
                      await setValue(
                        'preset.beatTimer.enabled',
                        checked,
                      );
                      trigger('preset.beatTimer.defaultDuration');
                      saveConfiguration(getValues());
                    }}
                  />
                );
              }}
            />
          }
        />
      </Box>
      <Box sx={{ m: 2 }}>
        <TextField
          fullWidth
          size="small"
          label="Default Timer Duration"
          placeholder="Enter timer duration"
          InputLabelProps={{ shrink: true }}
          {
            ...{
              error: !!formState.errors?.preset?.beatTimer,
              helperText: formState.errors?.preset?.beatTimer?.message,
            }
          }
          {
            ...register('preset.beatTimer.defaultDuration', {
              required: {
                value: !!watch('preset.beatTimer.enabled'),
                message: 'This field is required'
              },
              onChange: onDefaultDurationChange,
            })
          }
        />
      </Box>
    </>
  );
};
