import {
  Capabilities,
  Character,
  HistoryItem,
  InworldConnectionService,
  InworldPacket,
} from "@inworld/web-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useHotkeys } from 'react-hotkeys-hook';

import "./App.css";
import { Layout } from "./app/components/Layout";
import {
  Act,
  AppContext,
  Avatar,
  Beat,
  BeatDone,
  BEAT_TYPE,
  Configuration,
  CUSTOM_NEXT_EVENT,
  DoTrigger,
  ParticipantInteractions,
  PresetConfiguration,
  Step,
  Trigger,
} from "./app/types";
import { ConfigView } from "./app/configuration/ConfigView";
import { InworldService } from "./app/connection";
import { Chat } from "./app/chat/Chat";
import * as defaults from "./defaults";
import {
  get as getConfiguration,
  save as saveConfiguration,
} from "./app/helpers/configuration";
import { combineEventList, fetchExternalConfiguration, findNextStep } from "./app/helpers/app";
import { reDoTrigger, stepEvent } from "./app/helpers/transform";
import { Player } from "./app/components/sound/Player";

const ONE_SECOND = 1000;
const FINISH_EVENT = 'finish';

let doTrigger: DoTrigger = {};
let beatDone: BeatDone = {};
let beatInterval: NodeJS.Timer | undefined;
let silenceTimeout: NodeJS.Timer | undefined;
let participantInteractions: ParticipantInteractions = {};

const player = Player.getInstance();

function App() {
  const formMethods = useForm<Configuration>({ mode: "onChange" });
  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { isValid },
  } = formMethods;

  const {
    fields: actFields,
    append: actAppend,
    update: actUpdate,
    remove: actRemove,
  } = useFieldArray({
    control,
    name: 'preset.acts',
  });

  const [avatar, setAvatar] = useState<Avatar>({} as Avatar);
  const [initialized, setInitialized] = useState(false);
  const [connectionService, setConnectionService] = useState<InworldService>();
  const [connection, setConnection] = useState<InworldConnectionService>();
  const [character, setCharacter] = useState<Character>();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);
  const [chatting, setChatting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [hiddenConfig, setHiddenConfig] = useState(true);
  const [debugMode, setDebugMode] = useState(false);

  const [step, setStep] = useState<Step>(defaults.chat.step);
  const [nextStep, setNextStep] = useState<Step | undefined>();
  const [timer, setTimer] = useState(-1);
  const [waitingForStartPeriod, setWaitingForStartPeriod] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(false);
  const [waitingForParticipant, setWaitingForParticipant] = useState(false);
  const [triggerPlaying, setTriggerPlaying] = useState<Trigger>();
  const [waitingForFinish, setWaitingForFinish] = useState(false);
  const [interactionId, setInteractionId] = useState<string | undefined>("");
  const [strongInteractionId, setStrongInteractionId] = useState<
    string | undefined
  >();
  const [continueInteractionId, setContinueInteractionId] = useState<
    string | undefined
  >();
  const [participantInteractionId, setParticipantInteractionId] = useState<
    string | undefined
  >();
  const [isStrongInteractionEnd, setIsStrongInteractionEnd] = useState(false);
  const [isNextInteractionEnd, setIsNextInteractionEnd] = useState(false);
  const [nextTrigger, setNextTrigger] = useState<string>(
    CUSTOM_NEXT_EVENT.CONTINUE
  );

  const [
    currentBackgroundSound,
    setCurrentBackgroundSound
  ] = useState<HTMLAudioElement>();
  const [triggerSpeaking, setTriggerSpeaking] = useState(false);

  const [silentTimeoutIndex, setSilentTimeoutIndex] = useState(0);

  const [defaultConfiguration, setDefaultConfiguration] = useState<Configuration>({})

  const stateRef = useRef<AppContext>();
  stateRef.current = {
    characters,
    chatting,
    continueInteractionId,
    connectionService,
    interactionId,
    isNextInteractionEnd,
    isStrongInteractionEnd,
    nextTrigger,
    nextStep,
    participantInteractionId,
    silentTimeoutIndex,
    step,
    strongInteractionId,
    triggerPlaying,
    waitingForFinish,
    waitingForParticipant,
    waitingForPlayer,
    waitingForStartPeriod,
  };

  const clearBeatInterval = useCallback(() => {
    clearInterval(beatInterval);
    beatInterval = undefined;
  }, []);

  const participantSpeaking = useMemo(() => {
    return !!Object.keys(participantInteractions).length;
  }, []);

  const startPeriod = useCallback(() => {
    if (stateRef.current) {
      const { beatTimer, acts = [] } = getValues().preset || {};
      const {
        connectionService,
        step,
        waitingForParticipant,
      } = stateRef.current;

      let timeout =
        acts[step.act]?.beats?.[step.beat]?.overrideTimerDuration ??
        beatTimer?.defaultDuration;

      if (!timeout || !acts?.length) return;

      if (beatInterval) clearBeatInterval();

      beatInterval = setInterval(() => {
        setTimer(timeout!);
        timeout!--;

        if (timeout! < 0) {
          clearBeatInterval();

          const nextStep = findNextStep({
            acts,
            step: stateRef.current?.step!,
          });
          const isStrongBeat =
            acts[nextStep.act]?.beats?.[nextStep.beat].type ===
            BEAT_TYPE.STRONG;

          if (connectionService?.connection.player.isActive()) {
            setWaitingForPlayer(true);
          } else if (isStrongBeat &&
            !waitingForParticipant &&
            !participantSpeaking
          ) {
            setDisabled(true);
          }

          setNextStep(nextStep);
        }
      }, ONE_SECOND);
    }
  }, [clearBeatInterval, getValues, participantSpeaking]);

  const removeFromDoTrigger = useCallback(
    (interactionId: string) => {
      doTrigger = Object.keys(doTrigger).reduce((obj: DoTrigger, key: string) => {
        if (key !== interactionId) {
          obj[key] = doTrigger[key];
        }
        return obj;
      }, {});
    },
    []
  );

  const sendTrigger = useCallback(
    async (name: string) => {
      const { connectionService } = stateRef.current || {};

      // Beat is already sent. Do nothing
      if (beatDone.hasOwnProperty(name) && beatDone[name]) {
        return;
      }

      // Mark beat as sent
      if (beatDone.hasOwnProperty(name) && !beatDone[name]) {
        beatDone = {
          ...beatDone,
          [name]: true,
        };
      }

      return connectionService?.connection?.sendTrigger(name);
    },
    []
  );

  const stopCurrentBackgroundSound = useCallback(() => {
    if (currentBackgroundSound) {
      player.stop(currentBackgroundSound);
      setCurrentBackgroundSound(undefined);
    }
  }, [currentBackgroundSound]);

  const onPlayerAct = useCallback((packet?: InworldPacket) => {
    clearTimeout(silenceTimeout);
    setSilentTimeoutIndex(0);

    const { nextStep } = stateRef.current || {};

    const participantFinishSpeaking = packet?.text.final;
    const packetInteractionId = packet?.packetId.interactionId;

    if (participantFinishSpeaking) {
      setWaitingForParticipant(false);

      if (nextStep?.type === BEAT_TYPE.STRONG &&
        !beatInterval
      ) {
        setDisabled(true);
      }
    }

    if (packetInteractionId &&
      nextStep?.type === BEAT_TYPE.TRANSITIVE &&
      !stateRef.current?.participantInteractionId
    ) {
      setParticipantInteractionId(packetInteractionId);
    }
  }, []);

  const skipBeat = useCallback(async (toStep?: Step) => {
    const { acts = [] } = getValues().preset || {};
    const nextStep = toStep ?? findNextStep({ acts, step: stateRef.current?.step! });

    if (nextStep.act < step.act ||
      (nextStep.act === step.act && nextStep.beat <= step.beat)) {
      return;
    }

    stopCurrentBackgroundSound();

    clearBeatInterval();
    clearTimeout(silenceTimeout);
    setNextStep(nextStep);
    setWaitingForParticipant(false);
    doTrigger = {};
    participantInteractions = {};

    if (connectionService?.connection.player.isActive()) {
      setWaitingForPlayer(true);
    } else if (nextStep.type === BEAT_TYPE.STRONG) {
      setDisabled(true);
    }
  }, [
    clearBeatInterval,
    connectionService?.connection.player,
    getValues, 
    step,
    stopCurrentBackgroundSound
  ]);

  const scheduleNextTrigger = useCallback(
    (props: { ms: number; skip?: boolean }) => {
      clearTimeout(silenceTimeout);
      silenceTimeout = setTimeout(async () => {
        const { silentTimeoutIndex, step } = stateRef.current || {};

        if (props.skip) {
          return skipBeat();
        }

        let packet: InworldPacket | undefined;
        const event = (step?.type === BEAT_TYPE.TRANSITIVE &&
          stepEvent({ ...step, part: 2 }));

        if (
          event &&
          step &&
          beatDone.hasOwnProperty(event) &&
          !beatDone[event]
        ) {
          clearTimeout(silenceTimeout);
          setNextStep({ ...step, part: 2 });
        } else {
          packet = await sendTrigger(nextTrigger);
        }

        if (nextTrigger === CUSTOM_NEXT_EVENT.UPDATE)
          setNextTrigger(CUSTOM_NEXT_EVENT.CONTINUE);

        setSilentTimeoutIndex(silentTimeoutIndex! + 1);
        setContinueInteractionId(packet?.packetId.interactionId);
      }, props.ms * ONE_SECOND);
    },
    [nextTrigger, sendTrigger, skipBeat]
  );

  const playTrigger = useCallback(() => {
    const {
      step,
      triggerPlaying,
    } = stateRef.current ?? {};

    if (!triggerPlaying?.avatar?.src && !triggerPlaying?.sound?.src) return;

    setDisabled(true);

    if (triggerPlaying.avatar?.src) {
      setAvatar(triggerPlaying.avatar);
    }

    if (triggerPlaying.sound?.src) {
      player.play(triggerPlaying.sound.src, {
        afterPlaying: () => {
          setAvatar({
            alt: character?.getDisplayName(),
            src: defaultConfiguration.avatars?.remy!,
          });
          setTriggerSpeaking(false);
          setTriggerPlaying(undefined);

          const nextStep = findNextStep({
            acts: getValues().preset?.acts ?? [],
            step: step!,
          });

          if (nextStep.type === BEAT_TYPE.STRONG) {
            setWaitingForParticipant(false);
            setNextStep(nextStep);
          } else {
            setDisabled(false);
          }
        }
      });

      if (triggerPlaying.speaking) {
        setTriggerSpeaking(true);
      }
    }
  }, [defaultConfiguration, character, getValues]);


  const onTriggerMessage = useCallback((packet: InworldPacket) => {
    if (packet.trigger.name === FINISH_EVENT) {
      setDisabled(true);
      setWaitingForFinish(true);
      return;
    }

    if (packet.routing.source.isCharacter) {
      const trigger = defaultConfiguration?.triggers?.[packet.trigger.name];

      if (trigger) {
        setTriggerPlaying(trigger);
        if (!stateRef.current?.connectionService?.connection?.player?.isActive()) {
          playTrigger();
        }
        
        return;
      }

      const parts = reDoTrigger.exec(packet.trigger.name);

      if (parts?.[1] && stateRef.current?.step) {
        if (beatDone.hasOwnProperty(parts[1]) && !beatDone[parts[1]]) {
          clearTimeout(silenceTimeout);
          doTrigger = {
            ...doTrigger,
            [packet.packetId.interactionId]: parts[1],
          };
          setNextStep({ ...stateRef.current.step, part: 2 });
        } else if (!beatDone.hasOwnProperty(parts[1])) {
          doTrigger = {
            ...doTrigger,
            [packet.packetId.interactionId]: parts[1],
          };
        }
      }
    }
  }, [defaultConfiguration?.triggers, playTrigger]);

  const ensureStartPeriod = useCallback(() => {
    if (!stateRef.current?.connectionService?.connection?.player?.isActive()) {
      startPeriod();
    } else {
      setWaitingForStartPeriod(true);
    }
  }, [startPeriod]);

  const onMessage = useCallback(
    async (packet?: InworldPacket) => {
      if (!packet) return;

      const { interactionId: packetInteractionId } = packet.packetId;
      const {
        continueInteractionId,
        interactionId,
        participantInteractionId,
        step,
        strongInteractionId,
      } = stateRef.current || {};

      if (packet.isTrigger()) {
        onTriggerMessage(packet);
        return;
      }

      if (packet.isText() && packet.routing.source.isPlayer) {
        onPlayerAct(packet);

        if (packet.text.final) {
          delete participantInteractions[packetInteractionId];
        } else {
          participantInteractions[packetInteractionId] = true;
        }

        return;
      }

      if (packet.isInteractionEnd()) {
        delete participantInteractions[packetInteractionId];
      }

      // Start period manually unless server and client was already sent ***_part2 event
      if (step?.type === BEAT_TYPE.TRANSITIVE &&
        packet.isInteractionEnd() &&
        packetInteractionId === participantInteractionId &&
        !beatDone[stepEvent({ ...step, part: 2 })] &&
        !doTrigger?.[packetInteractionId]
      ) {
        ensureStartPeriod();
        return;
      }

      if (
        continueInteractionId === packetInteractionId &&
        packet.isInteractionEnd()
      ) {
        setIsNextInteractionEnd(true);
      } else if (
        packetInteractionId === interactionId &&
        packet.isInteractionEnd()
      ) {
        if (strongInteractionId === packetInteractionId) {
          setIsStrongInteractionEnd(true);
        }

        if (step?.type !== BEAT_TYPE.TRANSITIVE || step?.part === 2) {
          ensureStartPeriod();
        }
      }
    },
    [ensureStartPeriod, onTriggerMessage, onPlayerAct]
  );

  const finish = useCallback(() => {
    setDisabled(true);
    setWaitingForFinish(false);
    setWaitingForParticipant(false);

    // Stop audio playing and capturing
    setCurrentBackgroundSound(undefined);
    player.stop();
    connection?.player?.stop();
    connection?.player?.clear();
    connection?.recorder?.stop();
    connection?.recorder.setIsActive(false);

    // Stop timers
    setTimer(-1);
    setStep(defaults.chat.step);
    setNextStep(undefined);
    setTriggerSpeaking(false);
    setSilentTimeoutIndex(0);
    clearTimeout(silenceTimeout);
    clearBeatInterval();

    // Clear trigger event
    doTrigger = {};

    // Close connection and clear connection data
    connection?.close();
    setConnectionService(undefined);
  }, [clearBeatInterval, connection]);

  const onHistoryChange = useCallback((history: HistoryItem[]) => {
    setChatHistory(history);
  }, []);

  const afterPlaying = useCallback(
    (packet: InworldPacket) => {
      const { connectionService } = stateRef.current || {};
      const interactionId = packet.packetId.interactionId;
      if (
        doTrigger &&
        !connectionService?.connection?.player?.hasPacket({ interactionId })
      ) {
        const trigger = doTrigger[interactionId];

        if (trigger) {
          sendTrigger(trigger);
          removeFromDoTrigger(interactionId);
        }
      }
    },
    [sendTrigger, removeFromDoTrigger]
  );

  const beforePlaying = useCallback(() => {
    clearTimeout(silenceTimeout);
  }, []);

  const stopPlaying = useCallback(() => {
    if (!stateRef.current?.chatting) return;

    const { acts, progressStory } = getValues().preset || {};

    const {
      isNextInteractionEnd,
      isStrongInteractionEnd,
      nextTrigger,
      nextStep,
      silentTimeoutIndex,
      waitingForFinish,
      waitingForPlayer,
      waitingForStartPeriod,
      triggerPlaying,
    } = stateRef.current || {};

    clearTimeout(silenceTimeout);

    // End of experience
    if (waitingForFinish) {
      finish();
      return;
    }

    if (triggerPlaying) {
      return playTrigger();
    }

    // Start beat timer
    if (waitingForStartPeriod) {
      startPeriod();
      setWaitingForStartPeriod(false);
    }

    // Enable or disable input
    if (waitingForPlayer) {
      setDisabled(nextStep?.type === BEAT_TYPE.STRONG && !!acts?.length);
      setWaitingForPlayer(false);
    } else if (isStrongInteractionEnd) {
      setDisabled(false);
      setIsStrongInteractionEnd(false);
    }

    // Schedule transition event (part 2)
    if (
      progressStory?.enabled &&
      progressStory.minTime &&
      !Object.values(CUSTOM_NEXT_EVENT).includes(
        nextTrigger as unknown as CUSTOM_NEXT_EVENT
      )
    ) {
      scheduleNextTrigger({ ms: progressStory.minTime });
      // Schedule CUSTOM_NEXT_EVENT after minTime
    } else if (
      !silentTimeoutIndex &&
      (progressStory?.enabled || progressStory?.skipBeat) &&
      progressStory.minTime
    ) {
      scheduleNextTrigger({
        ms: progressStory.minTime,
        skip: progressStory.skipBeat,
      });
      // Schedule CUSTOM_NEXT_EVENT after maxTime
    } else if (isNextInteractionEnd && silentTimeoutIndex === 1) {
      if (
        progressStory?.enabled &&
        progressStory.maxTime &&
        progressStory.minTime
      ) {
        scheduleNextTrigger({
          ms: progressStory.maxTime - progressStory.minTime,
        });
      }

      setIsNextInteractionEnd(false);
    }
  }, [
    finish,
    getValues,
    playTrigger,
    scheduleNextTrigger,
    startPeriod
  ]);

  const start = useCallback(async () => {
    const form = getValues();

    setChatting(true);
    setPlayerName(form.player?.name!);
    setDebugMode(form.chatSettings?.debugMode!);

    const service = new InworldService({
      capabilities: {
        interruptions: form.chatSettings?.interruptions,
      } as Capabilities,
      onMessage,
      onHistoryChange,
      sceneName: form.preset?.scene?.name!,
      playerName: form.player?.name!,
      onReady: async () => {
        console.log("Ready!");
        setIsReady(true);
      },
      onDisconnect: () => {
        console.log("Disconnect!");
      },
      onAfterPlaying: afterPlaying,
      onBeforePlaying: beforePlaying,
      onStopPlaying: stopPlaying,
    });
    const characters = await service.connection.getCharacters();
    const character = characters.find(
      (c: Character) => c.getResourceName() === form.preset?.character?.name
    );

    if (character) {
      service.connection.setCurrentCharacter(character);
    }

    setConnection(service.connection);
    setConnectionService(service);
    setChatHistory(service.connection.getHistory());

    setAvatar({
      src: defaultConfiguration.avatars?.remy!,
      alt: character?.getDisplayName(),
    });
    setCharacter(character);
    setCharacters(characters);

    if (form.preset?.acts?.length) {
      const actIndex = 0;
      const beatIndex = 0;
      const {
        type,
        audioSrc,
        audioBackgroundSrc,
      } = form.preset?.acts[actIndex]?.beats?.[actIndex] || {};

      if (type !== BEAT_TYPE.STRONG) {
        setDisabled(false);
      }
  
      setNextStep({
        act: actIndex,
        beat: beatIndex,
        type: type!,
        audioSrc,
        audioBackgroundSrc,
      });

      if (character) {
        beatDone = combineEventList(getValues().preset?.acts);
      }
    } else {
      setDisabled(false);
    }
  }, [
    afterPlaying,
    beforePlaying,
    defaultConfiguration.avatars?.remy,
    getValues,
    onHistoryChange,
    onMessage,
    stopPlaying,
  ]);

  const backToSettings = useCallback(() => {
    beatDone = {};
    participantInteractions = {};

    setTriggerPlaying(undefined);
    setContinueInteractionId(undefined);
    setParticipantInteractionId(undefined);
    setStrongInteractionId(undefined);
    setWaitingForPlayer(false);

    // Disable flags
    setChatting(false);
    setIsReady(false);

    // Clear connection data
    setChatHistory([]);
    setCharacter(undefined);
    setCharacters([]);

    finish();
  }, [finish]);

  const resetForm = useCallback((scenario?: string) => {
    const { chatSettings } = defaultConfiguration;

    if (scenario) {
      const preset = scenario 
        ? defaultConfiguration
          ?.presets
          ?.find(({ key }) => key === scenario)
          ?.config as PresetConfiguration
        : undefined;

      reset({
        ...defaults.internalConfiguration,
        scenario,
        chatSettings,
        ...preset && { preset },
      });

      if (preset) {
        setHiddenConfig(preset.hidden ?? true);
      }
    } else {
      reset({
        ...{
          ...defaults.internalConfiguration,
          scenario: defaultConfiguration?.scenario,
          preset: defaultConfiguration?.preset,
          chatSettings,
        },
      });
    }

    saveConfiguration(getValues());
  }, [getValues, defaultConfiguration, reset]);

  const changeScenario = useCallback(async (scenario?: string) => {
    const saved = {
      ...defaults.internalConfiguration,
      ...getConfiguration(),
    };
    const key = scenario ||
      (getConfiguration() as Configuration).scenario ||
      defaultConfiguration.presets?.[0].key;

    if (!key) return;

    const { presets = {}, ...rest } = saved || defaults.internalConfiguration;

    const configuration = {
      ...rest,
      scenario: key,
      preset: presets[key] ??
        defaultConfiguration.presets?.find(({ key: presetKey }) => key === presetKey)?.config,
    };

    await reset(configuration);

    setHiddenConfig(configuration.preset?.hidden ?? true);

    if (scenario) {
      saveConfiguration(getValues());
    }
  }, [defaultConfiguration, getValues, reset]);

  const customize = useCallback(() => {
    const values = getValues();
    const custom = defaultConfiguration.presets?.find(({ config }) => config?.custom);

    saveConfiguration({
      ...values,
      scenario: custom?.key,
      preset: {
        ...values.preset,
        hidden: custom?.config?.hidden,
        custom: true,
        name: custom?.config?.name,
      },
    });

    changeScenario();
  }, [changeScenario, defaultConfiguration.presets, getValues, ]);

  // Initialize form
  useEffect(() => {
    async function asyncReset() {
      await changeScenario();

      trigger();
      setInitialized(true);
    }

    asyncReset();
  }, [changeScenario, trigger]);

  // Send current step
  useEffect(() => {
    const { progressStory, cookingAudio } = getValues().preset || {};

    async function sendEvent(step: Step) {
      const packet = await sendTrigger(stepEvent(step));

      if (packet) {
        setNextTrigger(
          step.type === BEAT_TYPE.WEAK
            ? CUSTOM_NEXT_EVENT.UPDATE
            : CUSTOM_NEXT_EVENT.CONTINUE
        );

        setParticipantInteractionId(undefined);
        setInteractionId(packet?.packetId?.interactionId);

        if ([BEAT_TYPE.STRONG, BEAT_TYPE.TRANSITIVE].includes(step.type)) {
          if (step.type === BEAT_TYPE.STRONG || step.part === 2) {
            setStrongInteractionId(packet?.packetId?.interactionId);
          } else if (
            step.type === BEAT_TYPE.TRANSITIVE &&
            !step.part &&
            progressStory?.minTime
          ) {
            clearTimeout(silenceTimeout);
            setNextTrigger(stepEvent({ ...step, part: 2 }));
            scheduleNextTrigger({ ms: progressStory.minTime });
          }
        }

        stopCurrentBackgroundSound();

        if ((step.type === BEAT_TYPE.TRANSITIVE && step.part) ||
          step.type !== BEAT_TYPE.TRANSITIVE) {
          setWaitingForParticipant(true);

          if (cookingAudio) {
            if (step.audioSrc) {
              player.play(step.audioSrc);
            }

            if (step.audioBackgroundSrc) {
              const element = player.play(step.audioBackgroundSrc, { loop: true });
              setCurrentBackgroundSound(element);
            }
          }
        }
      }
    }

    const shouldNotWaitParticipant = 
      !(participantSpeaking && nextStep?.type === BEAT_TYPE.STRONG) ||
      (nextStep?.act === 0 && nextStep?.beat === 0);

    if (
      shouldNotWaitParticipant &&
      !waitingForPlayer &&
      !waitingForParticipant &&
      !triggerPlaying &&
      connection &&
      nextStep &&
      nextStep?.act! >= 0 && nextStep?.beat! >= 0
    ) {
      setStep(nextStep);
      setNextStep(undefined);
      sendEvent(nextStep);
    }
  }, [
    connection,
    currentBackgroundSound,
    disabled,
    getValues,
    nextStep,
    participantSpeaking,
    scheduleNextTrigger,
    sendTrigger,
    stopCurrentBackgroundSound,
    triggerPlaying,
    waitingForParticipant,
    waitingForPlayer,
  ]);

  useEffect(() => {
    connectionService?.connection?.recorder.setIsActive(!disabled);
  }, [connectionService?.connection?.recorder, disabled]);

  useEffect(() => {
    fetchExternalConfiguration(
      config => setDefaultConfiguration({
        ...defaults.internalConfiguration,
        ...config,
        scenario: config.presets?.[0].key, 
        preset: config.presets?.[0].config,
      }),
      e => {
        console.log(e);
        setDefaultConfiguration({
          ...defaults.internalConfiguration,
          ...defaults.externalConfiguration,
        });
      }
    );
  }, [setDefaultConfiguration]);

  useEffect(() => {
    const { cookingAudio } = getValues().preset || {};
    const { intro, background } = defaultConfiguration?.sounds || {};

    const playBackground = () => {
      if (background?.src) {
        player.play(background.src, { loop: background.loop });
      }
    }

    const playIntro = (afterPlaying: () => void) => {
      player.play(intro.src!, { afterPlaying });
    }

    if (chatting && cookingAudio) {
      if (intro?.src) {
        playIntro(playBackground);
      } else {
        playBackground();
      }
    }
  }, [chatting, defaultConfiguration, getValues]);

  useHotkeys(
    'ctrl+i',
    () => {
      if (!chatting) {
        setHiddenConfig(!hiddenConfig);
      }
    },
    { enableOnFormTags: true },
    [chatting, hiddenConfig]
  );

  const content = chatting ? (
    <>
      {character ? (
        <Chat
          avatar={avatar}
          canSkip={step.act >=0}
          character={character}
          characterSpeaking={connection?.player.isActive() || triggerSpeaking}
          participantSpeaking={participantSpeaking}
          sessionId={connectionService?.getSessionToken()?.sessionId!}
          chatHistory={chatHistory}
          connection={connection!}
          debugMode={debugMode}
          disabled={disabled}
          isReady={isReady}
          playerName={playerName}
          step={step}
          nextStep={nextStep}
          timer={timer}
          onSend={onPlayerAct}
          onSkipBeat={skipBeat}
          onBackToSettings={backToSettings}
        />
      ) : (
        "Loading..."
      )}
    </>
  ) : (
    <ConfigView
      acts={actFields}
      canStart={isValid}
      canCustomize={!getValues().preset?.custom}
      hidden={hiddenConfig}
      configuration={defaultConfiguration}
      onActAdd={(act: Act) => {
        actAppend(act);
        saveConfiguration(getValues());
      }}
      onActDelete={(index: number) => {
        actRemove(index);
        saveConfiguration(getValues());
      }}
      onBeatAdd={(actIndex: number, beat: Beat) => {
        const { acts } = getValues().preset || {};

        if (acts?.[actIndex]) {
          actUpdate(actIndex, {
            ...acts[actIndex],
            beats: [...(acts[actIndex]?.beats || []), beat],
          });

          saveConfiguration(getValues());
        }
      }}
      onBeatDelete={(actIndex: number, beatIndex: number) => {
        const { acts } = getValues().preset || {};

        if (acts?.[actIndex] && acts[actIndex]?.beats?.length) {
          actUpdate(actIndex, {
            ...acts[actIndex],
            beats: acts[actIndex].beats?.filter(
              (_, index) => index !== beatIndex
            ),
          });

          saveConfiguration(getValues());
        }
      }}
      onStart={start}
      onCustomize={customize}
      onResetForm={resetForm}
      onChangeScenario={changeScenario}
      onSwitchConfig={(value) => setHiddenConfig(!value)}
    />
  );

  return (
    <FormProvider {...formMethods}>
      <Layout>{
        initialized && defaultConfiguration?.presets?.length
        ? content
        : ''
      }</Layout>
    </FormProvider>
  );
}

export default App;
