import { createTheme, responsiveFontSizes } from '@mui/material';

import { INWORLD_COLORS } from './colors';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: INWORLD_COLORS.purple[60],
        light: INWORLD_COLORS.purple[50],
        dark: INWORLD_COLORS.purple[70],
      },
      secondary: {
        main: INWORLD_COLORS.purple[100],
        light: INWORLD_COLORS.purple[90],
        dark: 'black',
      },
      success: {
        main: INWORLD_COLORS.green[50],
        dark: INWORLD_COLORS.green[60],
        light: INWORLD_COLORS.green[40],
      },
      warning: {
        main: INWORLD_COLORS.orange[50],
        light: INWORLD_COLORS.orange[20],
        dark: INWORLD_COLORS.orange[60],
      },
      text: {
        primary: INWORLD_COLORS.purple[100],
        secondary: '#5A5171',
        disabled: INWORLD_COLORS.warmGray[50],
      },
      background: {
        paper: '#ffffff',
        default: INWORLD_COLORS.warmGray[10],
      },
      grey: {
        '50': INWORLD_COLORS.gray[10],
        '100': INWORLD_COLORS.gray[20],
        '200': INWORLD_COLORS.gray[30],
        '300': INWORLD_COLORS.gray[40],
        '400': INWORLD_COLORS.gray[50],
        '500': INWORLD_COLORS.gray[60],
        '600': INWORLD_COLORS.gray[70],
        '700': INWORLD_COLORS.gray[80],
        '800': INWORLD_COLORS.gray[90],
        '900': INWORLD_COLORS.gray[100],
      },
    },
    typography: {
      fontFamily: '"GeneralSans-Variable", "DM Sans", "Arial", sans-serif',
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 500,
      fontWeightMedium: 500,
      h1: {
        fontSize: '4.75rem',
        lineHeight: 1,
        fontWeight: 600,
        letterSpacing: '-2px',
      },
      h2: {
        fontSize: '3.5rem',
        lineHeight: 1.14,
        fontWeight: 600,
        letterSpacing: '-2px',
      },
      h3: {
        fontSize: '2rem',
        lineHeight: 1.25,
        fontWeight: 600,
        letterSpacing: '-1px',
      },
      h4: {
        fontSize: '1.25rem',
        lineHeight: 1.4,
        fontWeight: 600,
        letterSpacing: '-1px',
      },
      h5: {
        fontSize: '1rem',
        lineHeight: 1.4,
        fontWeight: 600,
      },
      h6: {
        fontSize: '0.875rem',
        lineHeight: 1.4,
        fontWeight: 600,
      },
      button: {
        letterSpacing: '0',
        textTransform: 'none',
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.875rem',
      },
      caption: {
        fontSize: '0.75rem',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
            font-family: 'GeneralSans-Variable';
            src: url('https://assets.inworld.ai/fonts/GeneralSans-Variable.woff2') format('woff2'),
                url('https://assets.inworld.ai/fonts/GeneralSans-Variable.woff') format('woff'),
                url('https://assets.inworld.ai/fonts/GeneralSans-Variable.ttf') format('truetype');
                font-weight: 200 700;
                font-display: swap;
                font-style: normal;
          }
        `,
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            border: 'none',
            borderBottom: `solid 1px ${INWORLD_COLORS.warmGray[20]}`,
            boxShadow: 'none',
            backgroundColor: 'rgba(255,255,255,0.8)',
            backdropFilter: 'blur(16px)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          sizeSmall: {
            minHeight: '2rem',
          },
          sizeMedium: {
            minHeight: '2.5rem',
          },
          sizeLarge: {
            minHeight: '3rem',
          },
          root: {
            fontFamily: 'DM Sans',
            fontSize: '0.875rem',
            textTransform: 'none',
            borderRadius: 32,
            boxShadow: 'none',
            letterSpacing: 0,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: '0.75rem',
            borderColor: INWORLD_COLORS.warmGray[20],
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'DM Sans',
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'medium',
        },
        styleOverrides: {
          root: {
            fontFamily: 'DM Sans',
            fontWeight: '400',
            '& .MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${INWORLD_COLORS.warmGray[30]}`,
              fontWeight: '400',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            border: `solid 1px ${INWORLD_COLORS.warmGray[20]}`,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.25rem',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            background: INWORLD_COLORS.gray[10],
          },
        },
      },
    },
  }),
);

export type AppTheme = typeof theme;

export default theme;
