import { Character, CHAT_HISTORY_TYPE, HistoryItem, HistoryItemActor, HistoryItemTriggerEvent } from '@inworld/web-sdk';
import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { dateWithMilliseconds } from '../helpers/transform';
import { Configuration } from '../types';
import { HistoryStyled } from './Chat.styled';
import { Typing } from './Typing';

interface HistoryProps {
  character: Character;
  history: HistoryItem[];
  playerName: string;
}

type CombinedHistoryActorItem = HistoryItemActor & { groupedText: string };
type CombinedHistoryItem = CombinedHistoryActorItem | HistoryItemTriggerEvent;

export const History = (props: HistoryProps) => {
  const { character, history, playerName } = props;

  const ref = useRef<HTMLDivElement>(null);

  const { getValues } = useFormContext<Configuration>();

  const [combinedChatHistory, setCombinedChatHistory] = useState<
    CombinedHistoryItem[]
  >([]);
  const [isInteractionEnd, setIsInteractionEnd] = useState<boolean>(true);

  useEffect(() => {
    // scroll chat down on history change
    if (ref.current && history) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [history]);

  useEffect(() => {
    let currentRecord: CombinedHistoryActorItem | undefined;
    const mergedRecords: CombinedHistoryItem[] = [];
    const hasActors = history.find(
      (record: HistoryItem) => record.type === CHAT_HISTORY_TYPE.ACTOR,
    );
    const withoutCustomEvents = history.filter(
      (record: HistoryItem) => record.type !== CHAT_HISTORY_TYPE.TRIGGER_EVENT,
    );

    for (let i = 0; i < history.length; i++) {
      let item = history[i];
      switch (item.type) {
        case CHAT_HISTORY_TYPE.ACTOR:
          currentRecord = mergedRecords.find(r =>
            r.type === CHAT_HISTORY_TYPE.ACTOR &&
            item.type === CHAT_HISTORY_TYPE.ACTOR &&
            r.source.name === item.source.name &&
            r.interactionId === item.interactionId
          ) as CombinedHistoryActorItem;

          if (currentRecord) {
            currentRecord.groupedText! += `${item.text}`;
          } else {
            currentRecord = {
              ...item,
              groupedText: item.text,
            } as CombinedHistoryActorItem;
            mergedRecords.push(currentRecord);
          }
          break;
        case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
          mergedRecords.push(item);
          break;
      }
    }

    // Interaction is considered ended
    // when there is no actor action yet (chat is not started)
    // or last received message is INTERACTION_END.
    setIsInteractionEnd(
      !hasActors ||
        (
          !!currentRecord &&
          withoutCustomEvents[withoutCustomEvents.length - 1].type ===
            CHAT_HISTORY_TYPE.INTERACTION_END),
    );

    setCombinedChatHistory(mergedRecords);
  }, [history]);

  return (
    <HistoryStyled ref={ref}>
      {combinedChatHistory.map((item, index) => {
        let text;
        let author;
          const title = item.type === CHAT_HISTORY_TYPE.ACTOR || item.type === CHAT_HISTORY_TYPE.TRIGGER_EVENT
            ? `${dateWithMilliseconds(item.date)} (${item.interactionId})`
            : '';

        switch (item.type) {
          case CHAT_HISTORY_TYPE.ACTOR:
            text = item.groupedText;
            author = item.source.isCharacter
              ? character.getDisplayName()
              : playerName;
            break;
          case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
            if (getValues('chatSettings.debugMode')) {
              text = <Typography sx={{
                  fontWeight: 'bold',
                  color: (theme) => item.outgoing
                    ? theme.palette.info.dark
                    : theme.palette.info.light,
                }}>{item.name}</Typography>;
            }
            break;
          default:
            break;
        }

        return (
          <Box
            title={title}
            key={index}
            data-id={item.id}
            sx={{
              ...author && { textAlign: 'left' }
            }}
          >{
            author
              ? <><strong>{author}</strong>: {text}</>
              : text
          }
          </Box>
        );
      })}
      <Fade in={!isInteractionEnd} timeout={500}>
        <Box margin="0 0 5px">
          <Typing />
        </Box>
      </Fade>
    </HistoryStyled>
  );
};
