import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { save as saveConfiguration } from '../helpers/configuration';

export const CookingAudio = () => {
  const { control, getValues, setValue } = useFormContext<Configuration>();

  return (
    <FormControlLabel
      label="Cooking audio"
      control={
        <Controller
          name={'preset.cookingAudio'}
          control={control}
          render={({ field: { value } }) => {
            const val = value ?? false;

            return (
              <Switch
                checked={val}
                onChange={(_, checked) => {
                  setValue(
                    'preset.cookingAudio',
                    checked,
                  );
                  saveConfiguration(getValues())
                }}
              />
            );
          }}
        />
      }
    />
  );
};
