import {
  ContentCopy,
  CopyAll,
  Mic,
  Send,
} from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { Character, CHAT_HISTORY_TYPE, HistoryItem, InworldConnectionService, InworldPacket } from '@inworld/web-sdk';

import { Header } from '../components/Header';
import { Avatar, Step } from '../types';
import { History } from './History';
import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { CopyConfirmedDialog } from './CopyConfirmedDialog';
import { RecordIcon } from './Chat.styled';
import { dateWithMilliseconds } from '../helpers/transform';
import { SkipBeat } from '../configuration/SkipBeat';

interface ChatProps {
  avatar: Avatar;
  canSkip: boolean;
  character: Character;
  characterSpeaking: boolean;
  chatHistory: HistoryItem[];
  connection: InworldConnectionService;
  disabled: boolean;
  debugMode: boolean;
  isReady: boolean;
  nextStep?: Step;
  playerName: string;
  sessionId: string;
  step: Step;
  timer: number;
  onSend: (packet?: InworldPacket) => void;
  onSkipBeat: (step?: Step) => Promise<void>;
  onBackToSettings: () => void;
  participantSpeaking: boolean;
}

export function Chat(props: ChatProps) {
  const {
    avatar,
    canSkip,
    character,
    characterSpeaking,
    chatHistory,
    connection,
    debugMode,
    disabled,
    isReady,
    nextStep,
    playerName,
    sessionId,
    step,
    timer,
    onSend,
    onSkipBeat,
    onBackToSettings,
    participantSpeaking,
  } = props;

  const [text, setText] = useState('');
  const [copyDestination, setCopyDestination] = useState('');
  const [copyConfirmOpen, setCopyConfirmOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [hasPlayedWorkaroundSound, setHasPlayedWorkaroundSound] = useState(false);
  // Need to decide when send audioSessionStart/audioSessionEnd events
  // on disabling action (strong/transitive beats).
  const [recordingState, setRecordingState] = useState<boolean | undefined>();

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const formatTranscript = useCallback(
    (messages: HistoryItem[], fullFormat: boolean) => {
      let transcript = fullFormat
        ? `History for sessionId=${sessionId}\n`
        : '';
      let characterLastSpeaking = false; // Used to combine all Character text chunks

      messages.forEach((item) => {
        const additionalInfo = fullFormat
          ? ` (${dateWithMilliseconds(item.date)}: ${item.interactionId})`
          : '';

        switch (item.type) {
          case CHAT_HISTORY_TYPE.ACTOR:
            const isCharacter = item.source.isCharacter;
            const givenName = isCharacter
              ? item.character?.getDisplayName()
              : playerName;

            transcript += characterLastSpeaking && isCharacter && !fullFormat
              ? item.text
              : `\n${givenName}${additionalInfo}: ${item.text}`;
            characterLastSpeaking = isCharacter
            break;
          case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
            if (fullFormat) {
              transcript += `\n>>> ${item.name} ${additionalInfo}`;
              characterLastSpeaking = false;
            }
            break;
          case CHAT_HISTORY_TYPE.INTERACTION_END:
            if (fullFormat) {
              transcript += `\n>>> INTERACTION_END ${additionalInfo}`;
              characterLastSpeaking = false;
            }
            break;
        }
      });

      return transcript;
    },
    [playerName, sessionId],
  );

  const getTranscript = useCallback((
    messages: HistoryItem[],
    fullFormat: boolean,
  ) => {
      if (!messages.length) {
        return '';
      }

      // generate eventual transcript
      return formatTranscript(messages, fullFormat);
    },
    [formatTranscript],
  );

  const handleCopyClick = useCallback(async (fullFormat: boolean) => {
    const history = getTranscript(chatHistory, fullFormat);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(history).then(() => {
        setCopyDestination('clipboard');
      });
    } else {
      setCopyDestination('console');
    }

    setCopyConfirmOpen(true);
  }, [getTranscript, chatHistory]);

  const startAudioSession = useCallback(() => {
    connection.sendAudioSessionStart();
    setRecordingState(true);
  }, [connection]);

  const endAudioSession = useCallback(() => {
    connection.sendAudioSessionEnd();
    setRecordingState(false);
  }, [connection]);

  const stopRecording = useCallback(() => {
    connection.recorder.stop();
    setIsRecording(false);
    endAudioSession();
  }, [connection, endAudioSession]);

  const startRecording = useCallback(async () => {
    try {
      startAudioSession();
      await connection.recorder.start();
      setIsRecording(true);
    } catch (e) {
      console.error(e);
    }
  }, [connection, startAudioSession]);

  const playWorkaroundSound = useCallback(() => {
    // Workaround for browsers with restrictive auto-play policies
    connection.player.playWorkaroundSound();
    setHasPlayedWorkaroundSound(true);
  }, [connection, setHasPlayedWorkaroundSound]);

  const handleSend = useCallback(async() => {
    if (text) {
      !hasPlayedWorkaroundSound && playWorkaroundSound();

      const packet = await connection?.sendText(text);

      setText('');
      onSend(packet);
    }
  }, [connection, hasPlayedWorkaroundSound, onSend, playWorkaroundSound, text]);

  const handleTextKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  }, [handleSend]);

  const handleSpeakClick = useCallback(async () => { 
    !hasPlayedWorkaroundSound && playWorkaroundSound();

    if (isRecording) {
      stopRecording();
      connection.sendAudioSessionEnd();
      setIsRecording(false);
      return;
    }

    return startRecording();
  }, [
    connection,
    hasPlayedWorkaroundSound,
    isRecording,
    playWorkaroundSound,
    startRecording,
    stopRecording,
  ]);

  useEffect(() => {
    if (isReady) {
      startRecording();
    }
  }, [isReady, startRecording]);

  useEffect(() => {
    if (isRecording) {
     if (disabled && recordingState === true) {
        endAudioSession();
      } else if (!disabled && recordingState === false) {
        startAudioSession();
      }
    }
  }, [
    disabled,
    endAudioSession,
    isRecording,
    recordingState,
    startAudioSession
  ]);

  return (
    <>
      {debugMode && (<Header step={step} nextStep={nextStep} timer={timer} />)}
      <Grid
        container
        sx={{ mb: 2, mt: 10 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            backgroundColor: 'white',
            padding: '0.5rem 1.5rem',
            borderRadius: '1rem',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <History
            history={chatHistory}
            character={character}
            playerName={playerName}
          />
          <Stack direction="row-reverse" sx={{ mb: '1' }}>
            <IconButton title="Copy full history" onClick={() => handleCopyClick(true)}>
              <ContentCopy fontSize="small" />
            </IconButton>
            <IconButton title="Copy history" onClick={() => handleCopyClick(false)}>
              <CopyAll fontSize="small" />
            </IconButton>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <TextField
              variant="standard"
              fullWidth
              value={text}
              onChange={handleTextChange}
              onKeyPress={handleTextKeyPress}
              disabled={disabled}
              sx={{
                backgroundColor: (theme) => theme.palette.grey[100],
                borderRadius: '1rem',
                padding: '1rem',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled={disabled} onClick={handleSend}>
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
            <IconButton
              className={
                participantSpeaking && isRecording && !disabled
                  ? 'recorder speaking'
                  : 'recorder'
              }
              disabled={disabled}
              onClick={handleSpeakClick}
              sx={{ height: '3rem', width: '3rem', backgroundColor: '#F1F5F9' }}
            >
              {isRecording && !disabled ? <RecordIcon /> : <Mic />}
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={1}/>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            backgroundColor: 'white',
            padding: '0.5rem 1.5rem',
            borderRadius: '1rem',
            textAlign: 'center',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column'
          }}
          >
          <img
            className={
              characterSpeaking
                ? 'character-avatar speaking'
                : 'character-avatar'
            }
            alt={avatar.alt}
            src={avatar.src}
          />
          {debugMode
          ? (
              <Grid item>
                <SkipBeat canSkip={canSkip} onSkipBeat={onSkipBeat} />
              </Grid>
            )
          : ''
        }
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        spacing={2}
        alignItems="center"
        justifyContent={'flex-start'}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={onBackToSettings}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <CopyConfirmedDialog
        copyConfirmOpen={copyConfirmOpen}
        copyDestination={copyDestination}
        setCopyConfirmOpen={setCopyConfirmOpen}
      />
    </>
  );
}
