import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { save as saveConfiguration } from '../helpers/configuration';
import { toInt } from '../helpers/transform';

export const ProgressStory = () => {
  const { 
    control,
    getValues,
    formState,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<Configuration>();

  return (
    <>
      <Box sx={{ mx: 2, mt: 5 }}>
        <FormControlLabel
          label="Progress story"
          control={
            <Controller
              name={'preset.progressStory.enabled'}
              control={control}
              render={({ field: { value } }) => {
                const val = value ?? false;
                
                return (
                  <Switch
                    checked={val}
                    onChange={async (_, checked) => {
                      await setValue(
                        'preset.progressStory.enabled',
                        checked,
                      );

                      if (checked) {
                        setValue(
                          'preset.progressStory.skipBeat',
                          false,
                        );
                      }

                      trigger(['preset.progressStory.minTime', 'preset.progressStory.maxTime']);
                      saveConfiguration(getValues());
                    }}
                  />
                );
              }}
            />
          }
        />
        <FormControlLabel
          label="Skip beat"
          control={
            <Controller
              name={'preset.progressStory.skipBeat'}
              control={control}
              render={({ field: { value } }) => {
                const val = value ?? false;

                return (
                  <Switch
                    checked={val}
                    onChange={(_, checked) => {
                      setValue(
                        'preset.progressStory.skipBeat',
                        checked,
                      );
                      if (checked) {
                        setValue(
                          'preset.progressStory.enabled',
                          false,
                        );
                      }
                      saveConfiguration(getValues());
                    }}
                  />
                );
              }}
            />
          }
        />
      </Box>
      <Box sx={{ m: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="min-time-to-progress-story"
              size="small"
              label="Min Time To Progress Story"
              placeholder="Enter time"
              InputLabelProps={{ shrink: true }}
              {
                ...{
                  error: !!formState.errors?.preset?.progressStory?.minTime,
                  helperText: formState.errors?.preset?.progressStory?.minTime?.message,
                }
              }
              {
                ...register('preset.progressStory.minTime', {
                  required: {
                    value: !!watch('preset.progressStory.enabled'),
                    message: 'This field is required'
                  },
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(
                      'preset.progressStory.minTime',
                      toInt(event.target.value),
                    );
                    saveConfiguration(getValues());
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="max-time-to-progress-story"
              size="small"
              label="Max Time To Progress Story"
              placeholder="Enter time"
              InputLabelProps={{ shrink: true }}
              {
                ...{
                  error: !!formState.errors?.preset?.progressStory?.maxTime,
                  helperText: formState.errors?.preset?.progressStory?.maxTime?.message,
                }
              }
              {
                ...register('preset.progressStory.maxTime', {
                  required: {
                    value: !!watch('preset.progressStory.enabled'),
                    message: 'This field is required'
                  },
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(
                      'preset.progressStory.maxTime',
                      toInt(event.target.value),
                    );
                    saveConfiguration(getValues());
                  },
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
