import { Act, BeatDone, BEAT_TYPE, ExternalConfiguration, Step } from '../types';
import * as defaults from '../../defaults';
import { stepEvent } from './transform';
import { config } from '../../config';

export const findNextStep = (props: {
  acts: Act[],
  step: Step,
}): Step => {
  const { acts = [], step: { act, beat } } = props;

  let newAct = defaults.chat.step.act, newBeat = defaults.chat.step.beat;

  if (acts[act]?.beats?.length! - 1 > beat) {
    newAct = act;
    newBeat = beat + 1;
  } else if (acts.length - 1 > act) {
    newAct = act + 1;
    newBeat = 0;
  }

  const { audioSrc, audioBackgroundSrc, type } = acts[newAct]?.beats?.[newBeat] || {};

  return {
    act: newAct,
    beat: newBeat,
    type: type!,
    ...audioSrc && { audioSrc },
    ...audioBackgroundSrc && { audioBackgroundSrc },
  };
};

export const combineEventList = (acts?: Act[]) => {
  const beatDone: BeatDone = {};

  acts?.forEach(({ beats }, act) => {
    beats?.forEach(({ type }, beat) => {
      const step: Step = { act, beat, type: type! };

      beatDone[stepEvent(step)] = false;

      if (step.type === BEAT_TYPE.TRANSITIVE) {
        beatDone[stepEvent({ ...step, part: 2 })] = false;
      }
    });
  });

  return beatDone;
}

export const fetchExternalConfiguration = (
  resolve: (res: ExternalConfiguration) => void,
  reject: (e: any) => void
) => {
  // Prevent browser caching
  fetch(`${config.DEFAULTS_URL}?_${new Date().getTime()}`, {
      mode: 'cors',
      method: 'GET',
    }).then(async response => {
      try {
        // get text first in case of invalid JSON
        const result = await response.text();
        // then try to parse JSON
        resolve(JSON.parse(result) as ExternalConfiguration);
      } catch (e) {
        reject(e);
      }
    }).catch(e => {
      reject(e);
    });
}
