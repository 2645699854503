import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Act, Beat, Configuration } from '../types';
import { ActsAndBeats } from './ActsAndBeats';
import { CharacterName } from './CharacterName';
import { ChatSettings } from './ChatSettings';
import { CookingAudio } from './CookingAudio';
import { PlayerName } from './PlayerName';
import { PresetSelect } from './PresetSelect';
import { ProgressStory } from './ProgressStory';
import { SceneName } from './SceneName';
import { UseBeatTimer } from './UseBeatTimer';

interface ConfigViewProps {
  acts: Act[];
  canStart: boolean;
  canCustomize: boolean;
  hidden: boolean;
  configuration: Configuration;
  onActAdd: (act: Act) => void;
  onActDelete: (index: number) => void;
  onBeatAdd: (actIndex: number, beat: Beat) => void;
  onBeatDelete: (actIndex: number, beatIndex: number) => void;
  onCustomize: () => void;
  onStart: () => Promise<void>;
  onResetForm: (scenario?: string) => void;
  onChangeScenario: (scenario?: string) => void;
  onSwitchConfig: (value: boolean) => void;
}

export const ConfigView = (props: ConfigViewProps) => {
  const { getValues } = useFormContext<Configuration>();

  const generalPart = <Card sx={{ mb: 2 }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <PresetSelect
            onChangeScenario={props.onChangeScenario}
            configuration={props.configuration}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <PlayerName />
        </Grid>
        
      </Grid>
    </CardContent>
  </Card>;

  const hiddenPart = <>
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CharacterName />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SceneName />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mx: 2, mt: 5 }}>
              <ChatSettings />
              <CookingAudio />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ActsAndBeats
              acts={props.acts}
              configuration={props.configuration}
              onActAdd={props.onActAdd}
              onActDelete={props.onActDelete}
              onBeatAdd={props.onBeatAdd}
              onBeatDelete={props.onBeatDelete}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UseBeatTimer />
            <ProgressStory />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;

  const cards = (
    <>
      {generalPart}
      {props.hidden ? '' : (hiddenPart)}
    </>
  );
  return (
    <>
      <Box component="form">
        <Typography variant="h3" component="h1" sx= {{ m: 1 }}>
          Rat Chat with Remy
        </Typography>
        {cards}
      </Box>
      <Grid
        container
        mt={1}
        spacing={2}
        alignItems="center"
        justifyContent={'flex-end'}
      >
        <Grid item>
          {props.hidden
            ? ''
            : (<>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                onClick={() => props.onSwitchConfig(false)}
              >Hide</Button>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                onClick={() => props.onResetForm(getValues().scenario)}
              >Reset</Button>
            </>)
          }
          {
            props.canCustomize
              ? (
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    onClick={props.onCustomize}
                  >
                    Customize
                  </Button>
                )
              : ''
          }
          <Button
            disabled={!props.canStart}
            variant="contained"
            onClick={props.onStart}
          >
            Start
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
