import { Button, Grid, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Configuration, Step } from '../types';
import { toInt } from '../helpers/transform';

export const SkipBeat = (props: {
  canSkip: boolean;
  onSkipBeat: (step?: Step) => Promise<void>;
}) => {
  const { canSkip, onSkipBeat } = props;
  const { getValues, register } = useFormContext<Configuration>();

  return (
    <Grid
      container
      mt={1}
      spacing={2}
      alignItems="center"
      justifyContent={'flex-start'}
    >
      <Grid item>
        <TextField
          fullWidth
          id="skip-act"
          size="small"
          label="Act"
          placeholder="Enter act"
          InputLabelProps={{ shrink: true }}
          {...register('nextStep.act') }
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="skip-beat"
          size="small"
          label="Beat"
          placeholder="Enter beat"
          InputLabelProps={{ shrink: true }}
          {...register('nextStep.beat') }
        />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          disabled={!canSkip}
          onClick={() => {
            const { nextStep, preset } = getValues();

            if (nextStep?.act && nextStep.beat) {
              const act = toInt(nextStep?.act);
              const beat = toInt(nextStep?.beat);

              if (typeof act !== 'undefined' && typeof beat !== 'undefined') {
                const nextStep = { act: act - 1, beat: beat - 1 };
                const nextBeat = preset?.acts?.[nextStep.act]?.beats?.[nextStep.beat];

                if (nextBeat) {
                  onSkipBeat({
                    ...nextStep,
                    type: nextBeat.type!,
                    audioSrc: nextBeat.audioSrc,
                    audioBackgroundSrc: nextBeat.audioBackgroundSrc,
                  });
                }
              }
            }
          }}
        >
          Move To
        </Button>
      </Grid>
    </Grid>
  );
};
