import {
  Capabilities,
  HistoryItem,
  InworldClient,
  InworldConnectionService,
  InworldPacket,
  SessionToken,
} from '@inworld/web-sdk';
import { config } from '../config';


interface InworldServiceProps {
  capabilities?: Capabilities;
  sceneName: string;
  playerName: string;
  onMessage: (packet: InworldPacket) => Promise<void>;
  onReady: () => void;
  onDisconnect: () => void;
  onHistoryChange: (history: HistoryItem[]) => void;
  onAfterPlaying?: (packet: InworldPacket) => void;
  onBeforePlaying?: (packet: InworldPacket) => void;
  onStopPlaying?: () => void;
}

export class InworldService {
  session: SessionToken | undefined;
  connection: InworldConnectionService;

  constructor (props: InworldServiceProps) {
    const client = new InworldClient()
    .setConfiguration({
      capabilities: {
        emotions: true,
        silence: true,
        turnBasedStt: true,
        ...props?.capabilities,
      },
      connection: {
        ...config.INWORLD_GATEWAY && {
          gateway: { hostname: config.INWORLD_GATEWAY },
        },
      },
    })
    .setUser({ fullName: props.playerName })
    .setClient({ id: 'rat_client' })
    .setScene(props.sceneName)
    .setGenerateSessionToken(this.generateSessionToken.bind(this))
    .setOnError((err) => console.log(err))
    .setOnMessage(props.onMessage)
    .setOnReady(props.onReady)
    .setOnDisconnect(props.onDisconnect)
    .setOnHistoryChange(props.onHistoryChange)
    .setOnAfterPlaying(props.onAfterPlaying)
    .setOnBeforePlaying(props.onBeforePlaying)
    .setOnStopPlaying(props.onStopPlaying);

    this.connection = client.build();
  }

  private async generateSessionToken() {
    const response = await fetch(config.GENERATE_TOKEN_URL);

    this.session = await response.json();

    return this.session!;
  }

  getSessionToken() {
    return this.session;
  }
}
