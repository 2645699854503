import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { save as saveConfiguration } from '../helpers/configuration';

export const ChatSettings = () => {
  const { control, getValues, setValue } = useFormContext<Configuration>();

  return (
    <>
      <FormControlLabel
        label="Debug mode"
        control={
          <Controller
            name={'chatSettings.debugMode'}
            control={control}
            render={({ field: { value } }) => {
              return (
                <Switch
                  checked={value}
                  onChange={(_, checked) => {
                    setValue(
                      'chatSettings.debugMode',
                      checked,
                    );
                    saveConfiguration(getValues())
                  }}
                />
              );
            }}
          />
        }
      />
      <FormControlLabel
        label="Interruptions"
        control={
          <Controller
            name={'chatSettings.interruptions'}
            control={control}
            render={({ field: { value } }) => {
              return (
                <Switch
                  checked={value}
                  onChange={(_, checked) => {
                    setValue(
                      'chatSettings.interruptions',
                      checked,
                    );
                    saveConfiguration(getValues())
                  }}
                />
              );
            }}
          />
        }
      />
    </>
  );
};
