import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { save as saveConfiguration } from '../helpers/configuration';
import { useCallback, useMemo } from 'react';

const FIELD_NAME = 'preset.character.name';

export const CharacterName = () => {
  const { getValues, formState, register, setValue } = useFormContext<Configuration>();

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(FIELD_NAME, event.target.value);
    saveConfiguration(getValues());
  }, [getValues, setValue]);

  const errorMessage = useMemo(() =>
    formState.errors?.preset?.character?.name?.message,
    [formState]
  );

  return (
    <Box sx={{ m: 2 }}>
      <TextField
        fullWidth
        id="character-name"
        size="small"
        label="Character Name"
        placeholder="Enter character name"
        InputLabelProps={{ shrink: true }}
        {...{ error: !!errorMessage, helperText: errorMessage }}
        {...register(FIELD_NAME, {
          required: 'This field is required',
          onChange,
        })}
      />
    </Box>
  );
};
