import { Box, ListItemText, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Configuration } from '../types';
import { useCallback } from 'react';

const FIELD_NAME = 'scenario';

interface PresetProps {
  configuration: Configuration;
  onChangeScenario: (scenario?: string) => void;
}

export const PresetSelect = (props: PresetProps) => {
  const { control } = useFormContext<Configuration>();

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeScenario(event.target.value);
  }, [props]);

  return (
    <Box sx={{ m: 2 }}>
      <Controller
        name={FIELD_NAME}
        control={control}
        render={({ field: { value } }) => {
          const val =
            value ??
            props.configuration.scenario;
          return (
            <TextField
              select
              fullWidth
              id="preset"
              size="small"
              label="Scenario"
              placeholder="Select scenario"
              InputLabelProps={{ shrink: true }}
              value={val}
              onChange={onChange}
            >
              {props.configuration.presets?.map(({ key, config }) => (
                <MenuItem key={key} value={key}>
                  <ListItemText primary={config?.name} />
                </MenuItem>
              ))}
            </TextField>
          );
        }}
      />
    </Box>
  );
};
