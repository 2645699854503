import { Character } from "@inworld/web-sdk";
import { InworldService } from "./connection";

export type Avatar = {
  alt?: string;
  src: string;
};

export enum BEAT_TYPE {
  STRONG = "strong",
  WEAK = "weak",
  TRANSITIVE = "transitive",
}

export type BeatTimer = {
  enabled?: boolean;
  defaultDuration?: number;
};

export type ProgressStory = {
  skipBeat?: boolean;
  enabled?: boolean;
  minTime?: number;
  maxTime?: number;
};

export type Beat = {
  useTimerOverride?: boolean;
  overrideTimerDuration?: number;
  type?: BEAT_TYPE;
  audioSrc?: string;
  audioBackgroundSrc?: string;
};

export type Act = {
  beats?: Beat[];
};

export type chatSettings = {
  debugMode?: boolean;
  interruptions?: boolean;
};

export type ConfigurationCharacter = {
  name?: string;
};

export type ConfigurationScene = {
  name?: string;
};

export type ConfigurationPlayer = {
  name?: string;
};

export type PresetConfiguration = {
  name?: string;
  hidden?: boolean;
  custom?: boolean;
  character?: ConfigurationCharacter;
  scene?: ConfigurationScene;
  beatTimer?: BeatTimer;
  cookingAudio?: boolean;
  progressStory?: ProgressStory;
  acts?: Act[];
};

export type Preset = {
  // Need to identify scenario and keep it in localstorage on name change.
  key?: string;
  config?: PresetConfiguration;
}

export type Trigger = {
  avatar?: Avatar;
  sound?: SoundConfiguration;
  speaking?: boolean;
}

export type TriggersConfiguration = {
  [key: string]: Trigger;
}

export type AvatarsConfiguration = {
  [key: string]: string;
}

export type SoundConfiguration = {
  src?: string;
  loop?: boolean;
}

export type SoundsConfiguration = {
  [key: string]: SoundConfiguration;
}

export type InternalConfiguration = {
  scenario?: string;
  preset?: PresetConfiguration;
  player?: ConfigurationPlayer;
  chatSettings?: chatSettings;
  nextStep?: Step;
}

export type ExternalConfiguration = {
  avatars?: AvatarsConfiguration;
  chatSettings?: chatSettings;
  overrideTimerDuration?: number;
  presets?: Preset[];
  sounds?: SoundsConfiguration;
  triggers?: TriggersConfiguration;
};

export type Configuration = InternalConfiguration & ExternalConfiguration;

export interface Step {
  act: number;
  beat: number;
  part?: number;
  type: BEAT_TYPE;
  audioSrc?: string;
  audioBackgroundSrc?: string;
}

export enum CUSTOM_NEXT_EVENT {
  UPDATE = "update",
  CONTINUE = "continue",
}

export interface DoTrigger {
  [key: string]: string;
}

export interface BeatDone {
  [key: string]: boolean;
}

export interface ParticipantInteractions {
  [key: string]: boolean;
}

export interface AppContext {
  characters: Character[];
  chatting: boolean;
  connectionService?: InworldService;
  continueInteractionId?: string;
  interactionId?: string;
  isNextInteractionEnd?: boolean;
  isStrongInteractionEnd?: boolean;
  nextTrigger: string;
  nextStep?: Step
  participantInteractionId?: string;
  silentTimeoutIndex: number;
  step: Step;
  strongInteractionId?: string;
  triggerPlaying?: Trigger;
  waitingForFinish: boolean;
  waitingForParticipant: boolean;
  waitingForPlayer: boolean;
  waitingForStartPeriod: boolean;
}
