interface playProps {
  afterPlaying?: () => void;
  loop?: boolean;
}

export class Player {
  private static instance: Player;
  private elements: HTMLAudioElement[] = [];

  static getInstance(): Player {
    if (!this.instance) {
      this.instance = new Player();
    }
    return Player.instance;
  }

  play(src: string, props?: playProps) {
    const audioElement = document.createElement('audio');

    audioElement.src = src;
    audioElement.loop = !!props?.loop;
    document.body.append(audioElement);
    this.elements.push(audioElement);

    audioElement.addEventListener('ended', () => {
      if (props?.afterPlaying) {
        props.afterPlaying();
      }

      document.body.removeChild(audioElement);

      this.elements = this.elements.filter(e => e !== audioElement);
    });

    audioElement.src = src;

    audioElement.play()
      .then(() => {
        // Audio is playing.
      })
      .catch(error => {
        console.log(error);
      });

    return audioElement;
  }

  stop(element?: HTMLAudioElement) {
    if (element) {
      this.elements = this.elements.filter(e => e !== element);
      document.body.removeChild(element);
    } else {
      this.elements.forEach(e => {
        document.body.removeChild(e);
      });
      this.elements = [];
    }
  }
}
